<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div>
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.parents')"></h1>
                <Breadcrumbs :current="Route.PARENT_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">                
                <BaseButton @click.prevent="edit()">{{ $t('labels.parent-edit') }}</BaseButton>                
                <BaseButton variant="secondary" @click.prevent="() => impersonate(model?.id)">{{ $t('labels.impersonate') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.identifier') }}</dt>
                            <dd class="block">{{ model?.id }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.children') }}</dt>
                            <dd class="block">
                                <ul class="list-unstyled">
                                    <li v-for="child in model?.children"><RouterLink class="text-primary" :to="{ name: Route.CHILD_VIEW, params: { id: child.id } }">{{ child.first_name }} {{ child.last_name }}</RouterLink></li>
                                </ul>
                            </dd>
                        </div>          
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.name') }}</dt>
                            <dd class="block">{{ model ? `${model.first_name} ${model.last_name}` : '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.balance') }}</dt>
                            <dd class="block">${{ model?.balance || '0.00' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.email') }}</dt>
                            <dd class="block">{{ model?.email || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.phone1') }}</dt>
                            <dd class="block">{{ model?.phone1 || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.phone2') }}</dt>
                            <dd class="block">{{ model?.phone2 || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.phone3') }}</dt>
                            <dd class="block">{{ model?.phone3 || '-' }}</dd>
                        </div>
                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.address') }}</span>
                    </template>

                    <dl class="flex flex-col">
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-line1') }}</dt>
                            <dd class="block">{{ model?.address1 || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-line2') }}</dt>
                            <dd class="block">{{ model?.address2 || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-city') }}</dt>
                            <dd class="block">{{ model?.city || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-region') }}</dt>
                            <dd class="block">{{ model?.region || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-postal') }}</dt>
                            <dd class="block">{{ model?.postal_code || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-country') }}</dt>
                            <dd class="block">{{ model?.country || '-' }}</dd>
                        </div>
                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.registrations') }}</span>
                    </template>

                    <table class="table align-middle table-row-dashed gy-5 dataTable no-footer">
                        <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                            <tr class="text-start text-muted text-uppercase gs-0">
                                <th class="min-w-100px">{{ $t('labels.registration') }}</th>
                                <th class="min-w-100px">{{ $t('labels.client') }}</th>                                
                                <th class="min-w-100px">{{ $t('labels.time') }}</th>
                                <th class="min-w-100px text-right">{{ $t('labels.actions') }}</th>
                            </tr>                            
                        </thead>
                        <tbody class="fs-6 fw-semibold text-gray-600">
                            <tr v-if="!registrations.length" class="d-block d-md-table-row">
                                <td class="d-block d-md-table-cell text-center" colspan="5">{{ $t('labels.empty') }}</td>
                            </tr>
                            <tr v-for="row in registrations" :key="row.id">
                                <td>
                                    <div class="d-flex align-items-center border-0 p-0">                                        
                                        <div class="symbol symbol-50px">
                                            <div class="symbol-label bg-primary" :style="symbolStyle(row.registration)"></div>
                                        </div>
                                        <div class="ms-5">
                                            <div class="fw-bold text-gray-600">{{ trans({ en: row.name_en, fr: row.name_fr }) }} <div class="badge badge-dark">{{ row.registration.group.session.name }}</div></div>
                                            <div class="fs-7 text-muted">{{ row.child?.first_name }} {{ row.child?.last_name }}</div>
                                            <div class="fs-7 text-muted">{{ getStartDate(row.registration.group) }} {{ $t('labels.to') }} {{ getEndDate(row.registration.group) }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ row.registration?.group.activity.client.name }}</td>
                                <td>{{ time(row.registration.group.time_start) }} - {{ time(row.registration.group.time_end) }}</td>
                                <td class="text-right"><RouterLink class="btn btn-secondary btn-sm" :to="{ name: Route.REGISTRATION_VIEW, params: { id: row.registration.id } }">{{ $t('labels.details') }}</RouterLink></td>
                            </tr>
                        </tbody>
                    </table>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">

                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.orders') }}</span>
                    </template>

                    <table class="table align-middle table-row-dashed gy-5 dataTable no-footer">
                        <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                            <tr class="text-start text-muted text-uppercase gs-0">
                                <th class="min-w-100px">{{ $t('labels.id') }}</th>
                                <th class="min-w-100px">{{ $t('labels.status') }}</th>
                                <th class="min-w-100px">{{ $t('labels.total') }}</th>
                                <th class="min-w-100px">{{ $t('labels.date') }}</th>
                                <th class="min-w-100px text-right">{{ $t('labels.actions') }}</th>
                            </tr>                            
                        </thead>
                        <tbody class="fs-6 fw-semibold text-gray-600">
                            <tr v-if="!model?.orders?.length" class="d-block d-md-table-row">
                                <td class="text-center" colspan="5">{{ $t('labels.empty') }}</td>
                            </tr>
                            <tr v-for="row in model?.orders" :key="row.id">
                                <td>{{ transform('id', row) }}</td>
                                <td v-if="row.status == 'pending'"><span class="badge badge-light-danger">{{ t(`enum.order-status.failed`) }}</span></td>
                                <td v-else><span :class="badgeClasses(row)">{{ transform('status', row) }}</span></td>
                                <td>${{ row.total.toLocaleString() }}</td>
                                <td>{{ datetime(row.created_at) }}</td>
                                <td class="text-right"><RouterLink class="btn btn-secondary btn-sm" :to="{ name: Route.ORDER_VIEW, params: { id: row.id } }">{{ $t('labels.details') }}</RouterLink></td>
                            </tr>
                        </tbody>
                    </table>
                    
                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.transactions') }}</span>
                    </template>

                    <BaseTable class="w-full">

                        <template #header>
                            <BaseTableCell :header="true">{{ $t('labels.date') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.type') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.source') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.amount') }}</BaseTableCell>                                
                        </template>

                        <BaseTableRow v-for="transaction, index in model?.transactions" :key="index">
                            <BaseTableCell>{{ datetime(transaction.created_at) }}</BaseTableCell>
                            <BaseTableCell>{{ $t(`enum.transaction-type.${transaction.type}`) }}</BaseTableCell>
                            <BaseTableCell>{{ $t(`enum.transaction-source.${transaction.source}`) }}</BaseTableCell>    
                            <BaseTableCell>${{ transaction.amount }}</BaseTableCell>
                        </BaseTableRow>
                        
                    </BaseTable>   

                </BaseCard>

            </LoadingOverlay>

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()">{{ $t('labels.parent-edit') }}</BaseButton>                
                <BaseButton variant="secondary" @click.prevent="() => impersonate(model?.id)">{{ $t('labels.impersonate') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>        


    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { getEndDate, getStartDate } from '@/composables/activities'
    import { date, datetime, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useParentStore } from '@/store/model'
    import { useImpersonate } from '@/composables/impersonate'
    

    const store = useParentStore()
    const trans = useTrans()
    const { t } = useI18n()
    const { impersonate } = useImpersonate()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.PARENT_EDIT,
            index: Route.PARENT_INDEX
        }
    })

    const registrations = computed(() => {
        let results = []

        model.value?.children?.forEach(child => {
            child.registrations.forEach(registration => {
                results.push({ child, registration })
            })
        })

        return results
    })

    function symbolStyle(row) {
        if (!row.group?.activity?.course?.attachment) {
            return {}
        }

        return {
            'background-image': `url(${row.group.activity.course.attachment.url})`
        }
    }

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
     function transform (column, row) {
        switch (column) {
            case 'name':   return row.user ? `${row.user.first_name} ${row.user.last_name}` : ''
            case 'email':  return row?.user?.email
            case 'status': return row.status ? t(`enum.order-status.${row.status}`) : ''
            case 'total':  return row.total ? `$${row.total}` : ''
        }

        return row[column]
    }

    function badgeClasses(row) {
        return {
            'badge': true,
            'badge-light-info': row.status === 'credited' || row.status === 'refunded',
            'badge-light-success': row.status === 'complete',
            'badge-light-warning': row.status === 'pending' || row.status === 'processing',
            'badge-light-danger': row.status === 'failed',
        }
    }
</script>
