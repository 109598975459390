<template>

    <div ref="reference" role="combobox">

        <div class="d-flex align-items-center justify-content-between form-control form-control-solid filter-border"
            :class="disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white cursor-pointer'" style="min-height: 41px"
            tabindex="0" @click.stop.prevent="toggle()" @keyup.enter="toggle()">

            <div class="flex-grow-1">

                <slot name="selected">
                    <span :class="selectionClasses" v-text="selections"></span>
                </slot>

            </div>

            <div class="d-flex align-items-center gap-4 flex-shrink-0 ml-3">

                <slot name="clear">
                    <button v-if="isClearable" class="text-xs text-gray-400 outline-none focus:shadow"
                        @click.stop.prevent="clear()">
                        <i class="fa-duotone fa-xmark"></i>
                    </button>
                </slot>

                <slot name="caret">
                    <span class="text-xs text-gray-400">
                        <i class="fa-duotone fa-chevron-down"></i>
                    </span>
                </slot>

            </div>

        </div>

        <div ref="popper" class="z-10">

            <Transition enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">

                <div v-show="active" class="bg-white border border-gray-300 w-full transition">

                    <div v-if="searchable" class="flex">

                        <slot name="search" :query="query">
                            <input v-model="query"
                                class="block px-3 py-2 w-full outline-none border-b border-gray-300 focus:placeholder-transparent"
                                :placeholder="$t('component.listbox.search')">
                        </slot>

                    </div>

                    <div v-if="message" class="block px-3 py-2 font-medium">
                        <span>{{ message }}</span>
                    </div>

                    <ul ref="scrollbar" class="max-h-60 overflow-y-auto m-0 list-unstyled">

                        <slot v-if="creatable" name="create" :action="onCreate">
                            <li>
                                <button @click.stop.prevent="onCreate()" class="block px-3 py-2 font-medium">
                                    {{ $t('component.listbox.create') }}
                                </button>
                            </li>
                        </slot>

                        <li v-for="option in filtered" :key="option.value">

                            <slot name="option" :option="option" :selected="() => isSelected(option)"
                                :select="() => select(option)">

                                <button
                                    class="flex items-center justify-between px-3 py-2 hover:bg-gray-100 text-left w-full"
                                    tabindex="0" @keyup.enter="select(option)" @click.stop.prevent="select(option)">
                                    <slot name="label" :option="option">
                                        <span class="font-medium" v-text="option.label"></span>
                                    </slot>
                                    <i v-show="isSelected(option)"
                                        class="fa-duotone fa-circle-check text-success ml-3"></i>
                                </button>

                            </slot>

                        </li>

                    </ul>

                </div>

            </Transition>

        </div>

    </div>

</template>

<script setup>
import { useI18n } from '@/composables/i18n';
import { usePopper, usePopperFlip, useSetPopperWidth } from '@/composables/popper'

const emit = defineEmits(['created', 'deselect', 'select', 'update:modelValue'])

const props = defineProps({
    ajax: { type: Boolean, default: false },
    autoclose: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    creatable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    filter: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    id: { type: String },
    options: { type: [Array, Function], default: [] },
    outlined: { type: Boolean, default: true },
    perPage: { type: Number, default: 50 },
    searchable: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    minChars: { type: Number, default: -1 },
    modelValue: { required: false },
    label: { type: Function, default: option => option.text },
    value: { type: Function, default: option => option.id },
    search: { type: Function, default: (search) => [] },
    create: { type: Function, default: () => null },
    placeholder: { type: String, default: '' },
    transparent: { type: Boolean, default: false }
})

const { t } = useI18n()

const model = useVModel(props, 'modelValue', emit)

const ajax = toRef(props, 'ajax')
const autoclose = toRef(props, 'autoclose')
const clearable = toRef(props, 'clearable')
const creatable = toRef(props, 'creatable')
const disabled = toRef(props, 'disabled')
const filter = toRef(props, 'filter')
const multiple = toRef(props, 'multiple')
const options = toRef(props, 'options')
const loading = toRef(props, 'loading')
const minChars = toRef(props, 'minChars')
const searchable = toRef(props, 'searchable')
const label = toRef(props, 'label')
const value = toRef(props, 'value')
const search = toRef(props, 'search')
const create = toRef(props, 'create')
const perPage = toRef(props, 'perPage')
const placeholder = toRef(props, 'placeholder')
const transparent = toRef(props, 'transparent')

const scrollbar = ref(null)
const limit = ref(50)
const active = ref(false)
const resolved = ref([])
const cached = ref([])
const selected = ref([])
const query = ref('')
const searching = ref(false)

useEventListener(scrollbar, 'scroll', (event) => {
    const height = event.target.clientHeight
    const scrollHeight = event.target.scrollHeight - height
    const scrollTop = event.target.scrollTop
    const percentage = Math.floor(scrollTop / scrollHeight * 100)

    if (percentage > 60) {
        if (limit.value < resolved.value.length) {
            limit.value += perPage.value
        }

    }
})

const message = computed(() => {
    if (loading.value || searching.value) {
        return t('component.listbox.loading')
    }

    if (searchable.value && minChars.value !== -1) {
        const length = query.value?.length

        if (length < minChars.value) {
            return t('component.listbox.minimum', { min: minChars.value })
        }
    }

    if (!creatable.value && filtered.value.length === 0) {
        return t('component.listbox.empty')
    }
    return undefined
})

const isClearable = computed(() => clearable.value && selected.value.length > 0)

const filtered = computed(() => {
    let results = resolved.value

    if (filter.value && query.value.length > 0) {
        results = results.filter(option => {
            return option.label?.toLowerCase().includes(query.value?.toLowerCase())
        })
    }

    return results.slice(0, limit.value)
})

const selections = computed(() => {
    if (0 === selected.value?.length) {
        return placeholder.value
    }

    return selected.value?.map(option => option.label).join(', ')
})

const selectionClasses = computed(() => {
    const isPlaceholder = 0 === selected.value?.length

    return {
        'text-neutral-400': isPlaceholder
    }
})

const { instance, reference, popper } = usePopper({
    placement: 'bottom-start',
    modifiers: [
        usePopperFlip(),
        useSetPopperWidth()
    ]
})

onMounted(() => refresh())
onMounted(() => refreshAjax())
onClickOutside(reference, () => close())

watch(() => active.value, (value) => {
    if (!value) {
        query.value = ''
    }

    nextTick(() => instance.value?.forceUpdate())
})

watch(resolved, () => {
    refreshCache()
}, { deep: true })

watch(() => cached.value, () => refreshSelected(), { deep: true })
watch(() => model.value, () => refreshSelected(), { deep: true })
watch(model, () => refreshAjax(), { deep: true })

watch(query, () => {
    if (ajax.value && !filter.value) {
        searching.value = true
        debounceSearch()
    }
})

watch(() => options.value, () => {
    refresh()
}, { deep: true })

defineExpose({ refresh, prepend, append, open, close, toggle, isSelected, select, deselect })

const debounceSearch = useDebounceFn(() => {
    if (minChars.value !== -1 && query.value?.length < minChars.value) {
        resolved.value = []
        searching.value = false
        return
    }

    Promise.resolve(search.value?.({ query: query.value })).then(options => {
        resolveOptions(options)
    }).finally(() => {
        searching.value = false
    })
}, 300)

/**
 * Refresh the options.
 *
 * @return {Void}
 */
function refresh() {
    if (ajax.value && minChars.value === -1) {
        Promise.resolve(search.value?.({ query: query.value })).then(options => resolveOptions(options))
        return
    }

    if (typeof options.value === 'function') {
        Promise.resolve(options.value?.()).then(options => { resolveOptions(options) })
        return
    }

    resolveOptions(options)
}

function refreshAjax() {
        if (!ajax.value || filter.value) {
            return
        }

        let values = []

        if (Array.isArray(model.value)) {
            values = [...model.value]
        } else {
            if (model.value) {
                values.push(model.value)
            }
        }

        if (values.length === 0) {
            return
        }

        const cache = cached.value.map(option => option.value)
        const missing = values.filter(value => !cache.includes(value))

        if (missing.length === 0) {
            return
        }

        Promise.resolve(search.value?.({ values: missing })).then(options => {
            resolveOptions(options)
            refreshSelected()

            nextTick(() => { resolved.value = [] })
        })
    }

    function refreshCache() {
        const values = cached.value?.map(option => option.value)

        let results = []

        resolved.value?.forEach(option => {
            results.push(option)
        })

        cached.value = results
    }

    /**
     * Refresh selected options.
     *
     * @return {Void}
     */
    function refreshSelected() {
        const incoming = cached.value?.filter(option => isSelected(option)) || []

        selected.value = selected.value
            .filter(option => isSelected(option))
            .filter(option => !incoming.map(option => option.value).includes(option.value))
            .concat(incoming)
    }

    /**
     * Resolve the specified options.
     *
     * @param {Array} options
     */
    function resolveOptions(options) {
        resolved.value = unref(options).map(option => resolve(option))
    }

    /**
     * Resolve the specified option.
     *
     * @param {Object} option
     */
    function resolve(option) {
        return {
            value: value.value(option),
            label: label.value(option),
            internal: option
        }
    }

    /**
     * Resolve and prepend the specified option.
     *
     * @param {Object} option
     * @return {Object}
     */
    function prepend(option) {
        const result = resolve(option)

        resolved.value.unshift(result)

        return result
    }

    /**
     * Resolve and append the specified option.
     *
     * @param {Object} option
     * @return {Object}
     */
    function append(option) {
        const result = resolve(option)

        resolved.value.push(result)

        return result
    }

    /**
     * Open the options dropdown.
     *
     * @return {Void}
     */
    function open() {
        if (disabled.value) {
            return
        }

        active.value = true
    }

    /**
     * Close the options dropdown.
     *
     * @return {Void}
     */
    function close() {
        active.value = false
    }

    /**
     * Toggle the options dropdown.
     *
     * @return {Void}
     */
    function toggle() {
        if (disabled.value) {
            return
        }

        active.value = !active.value
    }

    /**
     * Indicates if the specified option is currently selected.
     *
     * @param {Object} option
     * @return {Boolean}
     */
    function isSelected(option) {
        if (multiple.value) {
            return Array.isArray(model.value) ? model.value.includes(option.value) : false
        }

        return model.value == option.value
    }

    /**
     * Handle creating new items.
     *
     * @param {any} arg
     * @return {Void}
     */
    function onCreate(arg) {
        Promise.resolve(create.value(arg)).then(result => {
            if (result) {
                select(prepend(result.data))
            }
        })

        if (autoclose.value) {
            nextTick(() => close())
        }
    }

    /**
     * Clear the selection.
     *
     * @return {Void}
     */
    function clear() {
        if (multiple.value) {
            model.value = []
        } else {
            model.value = null
        }

        if (autoclose.value) {
            nextTick(() => close())
        }
    }

    /**
     * Select the specified option. Deselects selected options in multiselect mode.
     *
     * @param {Object} option
     * @return {Void}
     */
    function select(option) {
        if (multiple.value) {
            if (isSelected(option)) {
                deselect(option)
                return
            }

            if (Array.isArray(model.value)) {
                model.value.push(option.value)
            } else {
                model.value = [option.value]
            }
        } else {
            model.value = option.value
        }

        if (autoclose.value) {
            nextTick(() => close())
        }
    }

    /**
     * Deselect the specified option.
     *
     * @param {Object} option
     * @return {Void}
     */
    function deselect(option) {
        if (!isSelected(option)) {
            return
        }

        if (multiple.value) {
            if (Array.isArray(model.value)) {
                model.value = model.value.filter(value => value !== option.value)
            } else {
                model.value = []
            }
        }
    }
</script>
