<template>
    <component :is="element" :class="computedClasses" :style="styles">
        <slot/>
    </component>
</template>

<script setup>
import { toRef, computed } from 'vue'

const props = defineProps({
    align: { type: String, default: 'left' },
    header: { type: Boolean, default: false },
    width: { required: false },
    overflow: { type: String, default: 'hidden' } // Add overflow prop
})

const align = toRef(props, 'align')
const header = toRef(props, 'header')
const width = toRef(props, 'width')
const overflow = toRef(props, 'overflow')

const element = computed(() => header.value ? 'th' : 'td')

const styles = computed(() => {
    let result = {}

    if (width.value) {
        result['min-width'] = `${width.value}px`
        result['max-width'] = `${width.value}px`
    }

    return result
})

const computedClasses = computed(() => {
    let classes = ['whitespace-nowrap']
    if (overflow.value !== 'auto') {
        classes.push('overflow-hidden')
    }
    return classes.join(' ')
})
</script>