<template>

    <LoadingOverlay :loading="loading">

        <div class="d-flex flex-col">

            <div class="d-flex align-items-center justify-content-between border-bottom border-gray-300">

                <button class="text-gray-400 fs-10 p-4" @click.prevent="prev()"><i class="fa-solid fa-chevron-left"></i></button>
                <span class="fw-bold text-gray-600 fs-7">{{ date.format('MMMM YYYY') }}</span>
                <button class="text-gray-400 text-xs p-4" @click.prevent="next()"><i class="fa-solid fa-chevron-right"></i></button>

            </div>

            <table class="calendar-table" :class="inverse ? 'calendar-table--inverse' : ''">
                <thead>
                    <th v-for="day in weekdays" :key="day" class="font-medium text-gray-800 p-3 text-xs text-center border-b border-neutral-200">
                        {{ day }}
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(row, i) in chunk(rows, 7)" :key="i">
                        <td v-for="(cell, j) in row" :key="j">
                            <div
                                class="calendar-cell"
                                :class="{
                                    'calendar-cell--current': cell.current,
                                    'calendar-cell--highlighted': cell.highlighted,
                                    'calendar-cell--holiday': cell.holiday,
                                    'calendar-cell--selected': cell.selected,
                                    'calendar-cell--dateExcluded': cell.dates_excluded
                                }"
                                @click.prevent="onClick(cell)">
                                <span class="calendar-cell-text" v-text="cell.text"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

    </LoadingOverlay>

</template>

<style scoped>
    .calendar-table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    .calendar-cell {
        align-items: center;
        aspect-ratio: 1/1;
        border-radius: 4px;
        cursor: pointer ;
        display: flex;
        font-size: 10px;
        font-weight: 600;
        opacity: 0.4;
        justify-content: center;
    }

    .calendar-cell--current {
        opacity: 1;
    }

    .calendar-cell--holiday {
        background-color: var(--bs-gray-200);
        color: #212121;
        opacity: 1;
    }

    .calendar-cell--highlighted {
        background-color: var(--bs-success);
        color: var(--bs-success-light);
        opacity: 1;
    }

    .calendar-table--inverse .calendar-cell--highlighted {
        background-color: var(--bs-warning);
        color: var(--bs-gray-900);
    }


    .calendar-cell--selected {
        background-color: var(--bs-warning);
        color: var(--bs-gray-900);
        opacity: 1;
    }
   .calendar-cell--dateExcluded {
       background-color: var(--bs-danger);
       color: var(--bs-gray-900);
       opacity: 1;
   }
    .calendar-table--inverse .calendar-cell--selected {
        background-color: var(--bs-success);
        color: var(--bs-success-light);
    }
</style>

<script setup>
    import { chunk, isArray } from 'lodash'
    import { useI18n } from '@/composables/i18n'
    import { isNullish } from '@/composables/utility'
    import { requestHolidays } from '@/composables/holidays'
    import dayjs from '@/plugins/dayjs'

    const { locale, t } = useI18n()

    const emit = defineEmits([
        'holidays:visible',
        'holidays:loaded',
        'update:modelValue',
        'update:dates_excluded'
    ])

    const props = defineProps({
        readonly: { type: Boolean, default: false },
        dateMinimum: { type: String },
        dateMaximum: { type: String },
        modelValue: { type: [Array, String], default: null },
        dates_excluded: { type: [Array, null], default: null },
        multiple: { type: Boolean, default: false },
        format: { type: String, default: 'YYYY-MM-DD' },
        highlighted: { type: Array, default: [] },
        enableHolidays: { type: Boolean, default: false },
        inverse: { type: Boolean, default: false },
    })

    const readonly = toRef(props, 'readonly')
    const multiple = toRef(props, 'multiple')
    const format = toRef(props, 'format')
    const highlighted = toRef(props, 'highlighted')
    const enableHolidays = toRef(props, 'enableHolidays')
    const inverse = toRef(props, 'inverse')

    const datepicker = inject('datepicker', null)

    const date = ref(dayjs())
    const holidaysYear = ref()
    const loading = ref(false)

    const model = useVModel(props, 'modelValue', emit)
    const model_dates_excluded = useVModel(props, 'dates_excluded', emit)

    const weekdays = computed(() => {
        let results = []

        // Ensure the correct locale is set.
        dayjs.locale(locale.value)

        // Get the starting and ending date for the week.
        const start = dayjs().startOf('isoWeek')
        const end   = dayjs().endOf('isoWeek')

        // Generate the resulting weekdays.
        let d = start.clone()

        while (d.isBefore(end)) {
            // Add the date to the results.
            results.push(d.format('dd'))

            // Move to the next calendar day.
            d = d.add(1, 'day')
        }

        return results
    })

    const holidays = computedAsync(
        async () => {
            let results = []

            try {
                const { data } = await requestHolidays(dayjs(date.value).year())

                // Set the holidays data.
                results =  data.holidays.map(holiday => ({
                    d: dayjs(holiday.date),
                    en: holiday.nameEn,
                    fr: holiday.nameFr
                }))
            } catch (e) {
                console.warn('Unable to load holidays:', e)
            }

            return results
        },
        []
    )

    const dates_excluded = computed(() => {
        let results = []

        if (!date.value)
            return results


        const start = date.value.clone().startOf('month').startOf('isoWeek')
        const end   = date.value.clone().endOf('month').endOf('isoWeek')

        let d = start.clone()

        while (d.isBefore(end)) {
            // Add the date to the results.
            results.push(d)

            // Move to the next calendar day.
            d = d.add(1, 'day')
        }

        return results
    })

    const currentHolidays = computed(() => {
        return dates_excluded.value.map(d => holidays.value.filter(i => d.isSame(i.d)).shift()).filter(d => !isNullish(d))
    })

    const rows = computed(() => {
        return dates_excluded.value.map(d => ({
            text: d.date(),
            value: d.format(format.value),
            current: d.isSame(date.value, 'month'),
            holiday: enableHolidays.value ? isHoliday(d) : false,
            highlighted: isHighlighted(d.format(format.value)),
            selected: isSelected(d.format(format.value)),
            dates_excluded: isDateExcluded(d.format(format.value))
        }))
    })

    onMounted(() => {
        if (model.value) {
            if (isArray(model.value) && 0 === model.value.length)
                return

            date.value = dayjs(model.value)
        }
        console.log(props.dates_excluded);
    })

    // Update the date picker when the date changes, if applicable.
    watch(date, () => {
        datepicker?.update?.()
    })

    const reset = () => date.value = dayjs()
    const prev = () => date.value = date.value.subtract(1, 'month')
    const next = () => date.value = date.value.clone().add(1, 'month')

    const isSelected = (value) => {
        if (!multiple.value) {
            return model.value === value
        }

        return model.value?.includes?.(value)
    }

    const isHighlighted = (value) => {
        return highlighted.value?.includes?.(value)
    }
    const isDateExcluded = (value) => {
        return model_dates_excluded.value?.includes?.(value)
    }

    const isHoliday = (d) => {
        return !isNullish(d) && currentHolidays.value.filter(i => dayjs(d).isSame(i.d)).length > 0
    }

    const onClick = (cell) => {
        if (readonly.value) {
            return
        }

        if (highlighted.value?.length > 0) {
            if (!highlighted.value.includes(cell.value)) {
                return
            }
        }

        if (!multiple.value) {
            model.value = cell.value
            return
        }

        if (!Array.isArray(model.value)) {
            model.value = [cell.value]
            return
        }

        const index = model.value.indexOf(cell.value)

        if (index !== -1) {

            model.value = model.value.filter((d, i) => i !== index);
            if (Array.isArray(model_dates_excluded?.value)) {
                const dindex = model_dates_excluded?.value.indexOf(cell.value);
                if (dindex == -1) {
                    model_dates_excluded.value.push(cell.value);
                }
            }

            return
        }
        if (Array.isArray(model_dates_excluded?.value)) {
            const drindex = model_dates_excluded?.value.indexOf(cell.value);
            if (drindex !== -1) {
                model_dates_excluded.value = model_dates_excluded.value.filter((d, i) => i !== drindex);

                return;
            }
        }

        model.value.push(cell.value)
    }

    defineExpose({ currentHolidays, reset })
</script>
