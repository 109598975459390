<template>

    <LoadingOverlay :loading="loading">

        <div class="card w-100 d-flex flex-col">

            <div class="card-body d-flex flex-col gap-6">

                <div class="row">

                    <div class="col-md-12">

                        <div
                            class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between gap-6 mb-6">
                            <h1 class="fs-2 text-center text-md-start"
                                v-text="trans({ en: activity?.course?.name_en, fr: activity?.course?.name_fr })"></h1>
                            <ul class="grade-list list-unstyled d-flex justify-content-center m-0">
                                <li v-for="(grade, index)  in activity?.grades" :key="grade.id">
                                    <div class="symbol symbol-circle symbol-40px m-0">
                                        <div class="symbol-label fs-8 fw-semibold">{{ trans({ en: grade.abbreviation_en,
                                            fr: grade.abbreviation_fr }) }}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-12">

                                <div
                                    class="d-flex align-items-center gap-4 bg-warning border border-dashed border-dark rounded p-4 mb-6 de-wobble">
                                    <i class="ki-duotone ki-check-square flex-shrink-0 fs-4x text-dark">
                                        <i class="path1"></i>
                                        <i class="path2"></i>
                                    </i>
                                    <div class="d-flex flex-col">
                                        <h4 class="text-dark">{{ $t('labels.children-selection') }}</h4>
                                        <p class="text-dark fs-7 m-0">{{ $t('message.children-selection') }}</p>
                                    </div>
                                </div>

                            </div>

                            <template v-for="(group, index) in groups" :key="group.id">

                                <div class="col-md-6">

                                    <div
                                        class="d-flex flex-col gap-6 border border-gray-300 border-dashed rounded p-6 mb-6">

                                        <div class="d-flex align-items-center justify-content-between gap-6">

                                            <div class="badge"
                                                :class="isRegistrationsOpen(group.internal) ? 'badge-success' :  'badge-danger'">
                                                {{ isRegistrationsOpen(group.internal) ? $t('labels.registrations-open')
                                                : $t('labels.registrations-closed') }}
                                            </div>

                                            <div v-if="!activity.current_group?.waiting_list"
                                                class="h-5px w-100 bg-light">
                                                <div class="rounded h-5px" role="progressbar" aria-valuenow="50"
                                                    aria-valuemin="0" aria-valuemax="100"
                                                    :class="progressClasses(group)" :style="progressStyle(group)"></div>
                                            </div>

                                        </div>

                                        <h4 v-if="group.internal?.session" class="m-0">{{ group.internal.session.name }}
                                        </h4>

                                        <div class="row g-12">

                                            <div class="col">
                                                <div
                                                    class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                    <div class="fs-4 fw-bold counted">
                                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                            <i class="ki-duotone ki-calendar-tick fs-4 text-gray-900 ">
                                                                <i class="path1"></i>
                                                                <i class="path2"></i>
                                                                <i class="path3"></i>
                                                                <i class="path4"></i>
                                                                <i class="path5"></i>
                                                                <i class="path6"></i>
                                                            </i>
                                                            {{ getStartDate(group.internal) }}
                                                        </a>
                                                    </div>
                                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.starts')
                                                        }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div
                                                    class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                    <div class="fs-4 fw-bold counted">
                                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                            <i class="ki-duotone ki-calendar-tick fs-4 text-gray-900 ">
                                                                <i class="path1"></i>
                                                                <i class="path2"></i>
                                                                <i class="path3"></i>
                                                                <i class="path4"></i>
                                                                <i class="path5"></i>
                                                                <i class="path6"></i>
                                                            </i>
                                                            {{ getEndDate(group.internal) }}
                                                        </a>
                                                    </div>
                                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.ends') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row g-12">
                                            <div class="col">
                                                <div
                                                    class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                    <div class="fs-4 fw-bold counted">
                                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                            <i class="ki-duotone ki-calendar-2 fs-4 text-gray-900 ">
                                                                <i class="path1"></i>
                                                                <i class="path2"></i>
                                                                <i class="path3"></i>
                                                                <i class="path4"></i>
                                                                <i class="path5"></i>
                                                            </i>
                                                            {{ getWeekdays(group.internal).join(', ') || '-' }}
                                                        </a>
                                                    </div>
                                                    <div class="fw-semibold fs-7 text-gray-400">{{
                                                        $t('labels.week-days') }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div
                                                    class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                    <div class="fs-4 fw-bold counted">
                                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                            <i class="ki-duotone ki-time fs-4 text-gray-900 ">
                                                                <i class="path1"></i>
                                                                <i class="path2"></i>
                                                            </i>
                                                            {{ time(group?.time_start) }} - {{ time(group?.time_end) }}
                                                        </a>
                                                    </div>
                                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.time') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div v-if="group.children.length > 0" class="flex flex-col gap-6">

                                            <h4 class="m-0 bg-warning border border-dashed border-dark rounded p-4">{{ $t('labels.children-selection') }}</h4>

                                            <ul class="list-unstyled d-flex flex-col gap-4 m-0">

                                                <li v-for="child in group.children" :key="child.id"
                                                    class="child-item p-6 rounded"
                                                    :class="child.selected ? 'child-item--active' : ''">

                                                    <div
                                                        class="d-flex align-items-center justify-content-between gap-6">
                                                        <label class="d-flex align-items-center gap-2 w-100">
                                                            <div class="form-check form-check--dark">
                                                                <input v-model="child.selected" class="form-check-input"
                                                                    :true-value="true" :false-value="false"
                                                                    type="checkbox">
                                                            </div>
                                                            <div
                                                                class="d-flex items-center justify-between gap-6 fw-semibold fs-7 w-100">
                                                                <span>{{ child.name }}</span>
                                                                <span class="fs-6 fw-bold">{{ groupPrice(group, child,
                                                                    true) }} <span v-if="taxed" class="fs-9">{{
                                                                        $t('labels.plus-tax') }}</span></span>
                                                            </div>
                                                            <i v-if="child.updateRequired || group.errors.includes(child.id)"
                                                                class="fa-duotone fa-circle-exclamation text-danger ml-2"></i>
                                                        </label>
                                                    </div>

                                                    <div v-if="options.length > 0" v-show="child.selected"
                                                        class="bg-white text-dark rounded p-4 mt-4">
                                                        <ul class="list-unstyled d-flex flex-col gap-4">
                                                            <li v-for="option in options" :key="option.id">
                                                                <label class="flex items-center">
                                                                    <div class="form-check form-check--dark">
                                                                        <input v-model="child.options"
                                                                            class="form-check-input" :value="option.id"
                                                                            type="checkbox">
                                                                    </div>
                                                                    <div
                                                                        class="d-flex items-center justify-between gap-6 fw-semibold fs-7 ml-2 w-100">
                                                                        <span>{{ trans({ en: option.name_en, fr: option.
                                                                            name_fr }) }}</span>
                                                                        <span class="fs-6 fw-bold">{{
                                                                            price(option.price) }} <span v-if="taxed"
                                                                                class="fs-9">{{ $t('labels.plus-tax')
                                                                                }}</span></span>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </li>

                                            </ul>

                                            <div class="d-flex justify-content-end">
                                                <div class="flex-shrink-0 text-right">
                                                    <span class="d-block fs-1 fw-bold">{{ price(compute(group)) }} <span
                                                            v-if="taxed" class="fs-4">{{ $t('labels.plus-tax')
                                                            }}</span></span>
                                                    <span class="d-block fs-6 text-muted">{{ $t('labels.subtotal')
                                                        }}</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </template>

                            <div class="col-md-6" v-if="groups.length === 1">
                                <div v-if="activity?.notes_public"
                                    class="border border-gray-300 border-dashed rounded p-6 mb-6">
                                    <h4 class="fs-6 mb-4">{{ $t('labels.notes') }}</h4>
                                    <div v-html="activity?.notes_public"></div>
                                </div>
                                <div v-else class="border border-gray-300 border-dashed rounded p-6 mb-6">
                                    <h4 class="fs-6 mb-4">{{ $t('labels.notes') }}</h4>
                                    <div class="text-gray-400">{{ $t('labels.no-notes') }}</div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="groups.length === 2">

                                <div
                                    class="save-now d-flex align-items-center gap-4 border border-primary border-dashed p-4 rounded mb-6 ">
                                    <i class="ki-duotone ki-dollar fs-4x text-primary">
                                        <i class="path1"></i>
                                        <i class="path2"></i>
                                        <i class="path3"></i>
                                    </i>
                                    <div class="d-flex flex-col">
                                        <h4 class="text-primary">{{ $t('labels.save-now') }}</h4>
                                        <p class="text-primary fs-7 m-0">{{ saveNow }}</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="col-md-4">

                        <div class="d-flex align-items-center gap-6 mb-6">

                            <div v-if="client?.payments_enable"
                                class="d-flex align-items-center gap-4 bg-warning border border-dashed border-dark rounded p-4">
                                <i class="ki-duotone ki-dollar flex-shrink-0 fs-4x text-dark">
                                    <i class="path1"></i>
                                    <i class="path2"></i>
                                    <i class="path3"></i>
                                </i>
                                <div class="d-flex flex-col">
                                    <h4 class="text-dark">{{ $t('labels.multiple-payments') }}</h4>
                                    <p class="text-dark fs-7 m-0">{{ $t('message.multiple-payments') }}</p>
                                </div>
                            </div>

                            <div class="flex-shrink-0">
                                <span class="d-block fs-1 fw-bold">{{ price(subtotal) }} <span v-if="taxed"
                                        class="fs-4">{{ $t('labels.plus-tax') }}</span></span>
                                <span class="d-block fs-6 text-muted">{{ $t('labels.grand-total') }}</span>
                            </div>

                        </div>

                        <div class="d-flex justify-content-end gap-4 my-6">

                            <BaseButton variant="secondary" @click.prevent="back()">
                                {{ $t('labels.back') }}
                            </BaseButton>

                            <BaseButton v-if="added" @click.prevent="next()">
                                {{ $t('labels.continue') }}
                            </BaseButton>

                            <BaseButton v-if="!added" @click.prevent="commit()" :disabled="updateRequired">
                                <i class="ki-duotone ki-basket-ok fs-6 mr-2">
                                    <i class="path1"></i>
                                    <i class="path2"></i>
                                    <i class="path3"></i>
                                    <i class="path4"></i>
                                </i>
                                {{ $t('labels.cart-add') }}
                            </BaseButton>

                        </div>

                        <div v-if="groups.length === 2">
                            <div v-if="activity?.notes_public"
                                class="border border-gray-300 border-dashed rounded p-6 my-6">
                                <h4 class="fs-6 mb-4">{{ $t('labels.notes') }}</h4>
                                <div v-html="activity?.notes_public"></div>
                            </div>
                            <div v-else class="border border-gray-300 border-dashed rounded p-6 my-6">
                                <h4 class="fs-6 mb-4">{{ $t('labels.notes') }}</h4>
                                <div class="text-gray-400">{{ $t('labels.no-notes') }}</div>
                            </div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded p-6 my-6">
                            <div class="block font-semibold mb-2">{{ $t('labels.dates') }}</div>
                            <div class="block">
                                <ul class="list-disc pl-4 mb-0">
                                    <li v-for="(d, index) in filteredDates" :key="index" v-text="d"></li>
                                </ul>
                            </div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded p-6 my-6" v-if="filteredDateExcluded.length > 0">
                            <div class="block font-semibold mb-2">{{ $t('labels.dates-without-course') }}</div>
                            <div class="block">
                                <ul class="list-disc pl-4 mb-0">
                                    <li v-for="(dx, index) in filteredDateExcluded" :key="index" v-text="dx"></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">

                        <div class="d-block mb-6">
                            <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded bg-primary"
                                :style="imageStyle(activity)"></div>
                        </div>

                    </div>

                    <div class="col-md-4">
                        <div class="d-flex flex-col gap-6 border border-gray-300 border-dashed rounded p-6">
                            <div v-html="description(activity?.course)"></div>
                        </div>

                        <BaseButton v-if="video" @click.prevent="$refs?.modal?.open()">
                            {{ $t('labels.video') }}
                        </BaseButton>
                    </div>

                </div>


            </div>

        </div>

    </LoadingOverlay>

    <BaseModal v-if="video" ref="modal" class="max-w-[768px]" :title="$t('labels.video')">

        <template #body>

            <div class="relative w-full aspect-video">
                <iframe class="absolute top-0 left-0 w-full h-full" :src="embed" frameborder="0"></iframe>
            </div>

        </template>

    </BaseModal>

    <BaseModal ref="schedule" class="max-w-[768px]" :title="$t('labels.schedule')">

        <template #body>

            <div class="max-h-64 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300 overflow-y-scroll">

                <table class="w-full text-sm">
                    <thead>
                        <th class="px-6 py-2 first-child:pl-0 text-left">{{ $t('labels.date') }}</th>
                        <th class="px-6 py-2 last-child:pr-0 text-left">{{ $t('labels.week-day') }}</th>
                        <th class="px-6 py-2 last-child:pr-0 text-left">{{ $t('labels.time') }}</th>
                    </thead>
                    <tbody>
                        <tr v-if="group?.dates" v-for="d in group?.dates" :key="d.id">
                            <td class="border-t border-gray-300 px-6 py-2 first-child:pl-0" v-text="date(d.date)"></td>
                            <td class="border-t border-gray-300 px-6 py-2 first-child:pl-0" v-text="weekday(d.date)">
                            </td>
                            <td class="border-t border-gray-300 px-6 py-2 last-child:pr-0">{{ time(group.time_start) }}
                                - {{ time(group.time_end) }}</td>
                        </tr>
                        <tr v-if="0 === group?.dates?.length">
                            <td class="border-t border-gray-300 px-6 py-2 first-child:pl-0" colspan="2">
                                {{ $t('labels.empty') }}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </template>

    </BaseModal>

</template>

<style scoped>
    .grade-list li:not(:first-child) {
        margin-left: -5px;
    }

    .grade-list .symbol-label {
        position: relative;
        z-index: 1;
    }
    .grade-list li .symbol-label {
        background-color: #f0f6ff;
    }

    .grade-list li:nth-child(even) .symbol-label {
        background-color: #a2a5b7;
        color: #fff;
    }

    .child-item {
        background-color: #f9f9f9;
    }

    .child-item--active {
        background-color: var(--bs-primary);
        color: #fff;
    }

    .form-check--dark .form-check-input {
        background-color: #e1e3eb;
    }

    .form-check--dark .form-check-input:checked {
        background-color: #1a1c31;
    }

    .save-now {
        background-color: var(--bs-primary-light);
    }
</style>

<script setup>
    import { useRoute } from 'vue-router'
    import { products } from '@/api'
    import Route from '@/constants/route'
    import ToastType from '@/constants/toast-type'
    import { isRegistrationsOpen, getRegistrationPercentage, getStartDate, getEndDate, getWeekdays } from '@/composables/activities'
    import { date, time, weekday } from '@/composables/dates'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { toast } from '@/composables/toast'
    import { isNullish } from '@/composables/utility'
    import { useAuthStore } from '@/store/auth'
    import { useCartStore } from '@/store/cart'
    import { useShopStore } from '@/store/shop'
    import { computed } from 'vue'

    const auth = useAuthStore()
    const cart = useCartStore()
    const store = useShopStore()
    const trans = useTrans()
    const router = useRouter()
    const route = useRoute()
    const { locale, t } = useI18n()

    const shop = inject('shop', null)

    const client = computed(() => store.client)
    const activity = computed(() => store.activity)
    const taxed = computed(() => store.taxed || store.client?.taxed)
    const options = computed(() => activity.value?.course?.options || [])

    const children = computed(() => {
        const grades = activity.value?.grades?.map(grade => grade.id) || []
        const result = client.value?.children?.filter(child => grades.includes(child.grade_id) && !child.archived)
        return result || []
    })

    const updateRequired = computed(() => {
        let result = false

        children.value?.forEach(child => {
            if (child.update_required) {
                result = true
            }
        })

        return result
    })

    const video = computed(() => {
        switch (locale.value) {
            case 'en': return activity.value?.course?.video_en
            case 'fr': return activity.value?.course.video_fr
        }
    })

    const loading = ref(false)
    const modal = ref()
    const schedule = ref()
    const errors = ref([])
    const groups = ref([])
    const group = ref()
    const item = ref(null)
    const added = ref(false)

    const embed = computed(() => {
        if (!modal.value?.active) {
            return
        }

        const youtube = video.value?.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/)
        const vimeo   = video.value?.match(/(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/)

        if (youtube) {
            return `https://www.youtube.com/embed/${youtube[2]}`
        }

        if (vimeo) {
            return `https://player.vimeo.com/video/${vimeo[1]}`
        }

        return video
    })

    const subtotal = computed(() => {
        const results = groups.value?.map(group => compute(group))

        return results?.reduce((sum, accumulator) => sum + accumulator, 0)
    })

    const rebate = computed(() => {
        let result = 0

        groups.value?.forEach?.(group => {
            console.log(group)
            if (group.price_rebate > 0) {
                result = group.price - group.price_rebate
            }
        })

        return result
    })

const filteredDates = computed(() => {
    let list= groups.value?.map?.(group => {
      if (Array.isArray(group.dates)) {
            return [...group.dates];
        } else {
            return [];
        }
    })
    return list ? list.flat() : [];

    })
const filteredDateExcluded = computed(() => {
    let list = groups.value?.map?.(group => {
        if (Array.isArray(group.dates_excluded)) {
            return [...group.dates_excluded];
        } else {
            return [];
        }
    })
    return list ? list.flat() : [];

})
    const saveNow = computed(() => t(`message.save-now`).replace(':rebate', rebate.value.toFixed(2)))

    onMounted(async () => {
        cart.refresh().then(() => {
            if (!isNullish(route.params.key)) {
                edit(route.params.key)
                return
            }

            refresh()
        })
    })

    watch(() => children.value, () => { refresh() })

    async function edit(key) {
        const item = Object.values(cart.items).filter(item => item.key === key).shift()

        if (!item) {
            console.error(`Error locating item for key: ${key} `)
            return
        }

        loading.value = true

        try {
            await auth.refresh()

            const { data }  = await products.index()

            const itemClient = data.data.clients.filter(client => client.id === item.data.group.activity.client_id).shift()

            if (!itemClient) {
                console.error(`Error locating client entry for key: ${key}`)
                return
            }

            const itemActivity = itemClient.activities.filter(activity => activity.id === item.data.group.activity.id).shift()

            if (!itemActivity) {
                console.error(`Error locating activity entry for key: ${key}`)
                return
            }

            console.log(itemClient)

            // Set the store state.
            store.setClient(itemClient, data.data.taxed || itemClient.taxed)
            store.setActivity(itemActivity)

            // Refresh the group data.
            refresh()
        } catch (e) {
            console.log(e)
        } finally {
            loading.value = false
        }
    }

function groupPrice(group, child, format = false) {
        let result = group.price

        if (group.price_rebate) {
            const matching = groups.value
                ?.filter?.(g => g.id !== group.id)
                ?.filter(g => g.children.filter(c => c.id === child.id && c.selected).length > 0)

            if (matching.length > 0) {
                result = group.price_rebate
            }
        }

        if (format) {
            return price(result)
        }

        return parseFloat(result)
    }

    function compute(group) {
        const children = group.children.filter(child => child.selected)

        if (0 === children.length) {
            return 0
        }

        const basePrice = children
            .map(child => groupPrice(group, child, false))
            .reduce((sum, accumulator) => sum + accumulator, 0)

        const optionPrice = children.map(child => {
            return options.value
                ?.filter(option => child.options.includes(option.id))
                ?.map(option => parseFloat(option.price || 0))
                ?.reduce((sum, accumulator) => sum + accumulator, 0)
        }).reduce((sum, accumulator) => sum + accumulator, 0)

        return parseFloat(basePrice) + parseFloat(optionPrice)
    }

    function refresh() {
        // Set the current step.
        shop?.setStep(4)

        groups.value = []

        // If no activity is set, return to the second step.
        if (!activity.value) router.push({ name: Route.SHOP_STEP_2 })

        activity.value?.groups?.forEach(group => {
            const dates = group.dates
                .map(d => dayjs(d.date))
                .sort((a, b) => a.isAfter(b) ? 1 : -1)
                .map(d => d.format('YYYY-MM-DD'))
            console.log(group)
            const dateExcluded= group?.dates_excluded
                .map(dx => dayjs(dx.date))
                .sort((a, b) => a.isAfter(b) ? 1 : -1)
                .map(dx => dx.format('YYYY-MM-DD'))
            /** Note from Tuhin: I am not sure why only two dates should be shown to the user. I have to make it commented to implement SA019
            if (dates.length > 2) {
                dates.splice(1, dates.length - 2)
            }
             */
            let items = []

            Object.values(cart.items).forEach?.(item => {
                if (item.group !== group.id) {
                    return
                }

                item.children.forEach(child => {
                    items.push({
                        child: child.id,
                        options: item.options.map(option => option.id),
                    })
                })
            })

            const groupChildren = children.value?.map(child => {
                let selected = false
                let options = []

                const matching = items.filter(item => item.child == child.id).shift()

                if (!isNullish(matching)) {
                    selected = true
                    options = matching.options
                }

                return {
                    id: child.id,
                    name: `${child.first_name} ${child.last_name}`,
                    updateRequired: child.update_required,
                    selected: selected,
                    options: options
                }
            })

            groups.value.push({
                id: group.id,
                price: group.price,
                price_rebate: group.price_rebate,
                dates: dates,
                dates_excluded: dateExcluded,
                time_start: group.time_start,
                time_end: group.time_end,
                registration_start: date(group.registration_start),
                registration_end: date(group.registration_end),
                errors: [],
                children: groupChildren || [],
                internal: group
            })
        })
    }

    /**
     * Return to the previous step.
     *
     * @return {void}
     */
    function back() {
        router.push({ name: Route.SHOP_STEP_3 })
    }

    /**
     * Continue to the next step.
     *
     * @return {void}
     */
    function next() {
        router.push({ name: Route.CHECKOUT })
    }

    /**
     * Commit the selection to the cart.
     *
     * @return {void}
     */
     function commit() {
        const selected = groups.value
            ?.map(group => group.children.filter(child => child.selected).length)
            ?.reduce((sum, accumulator) => sum + accumulator, 0)

        if (selected === 0) {
            toast(ToastType.ERROR, t('message.cart-children-empty'))
            return
        }

        // Reset group errors.
        groups.value.forEach(group => { group.errors = [] })

        // Set the loading flag.
        loading.value = true

        // Get the groups being added to the cart.
        let items = []
        let groupIds = []

        groups.value?.forEach(group => {
            const children = group.children.filter(child => child.selected)

            if (0 === children.length) {
                return
            }

            if (!groupIds.includes(group.id)) {
                groupIds.push(group.id)
            }

            children.forEach(child => {
                items.push({
                    group: group.id,
                    children: [child.id],
                    options: Object.values(child.options),
                })
            })
        })

        let promises = []

        Object.values(cart.items).forEach(item => {
            if (groupIds.includes(item.group)) {
                promises.push(cart.remove(item.key))
            }
        })

        Promise.all(promises).then(() => {
            cart.add({ items: items }).then(() => {
                toast(ToastType.SUCCESS, t('message.cart-added'))

                added.value = true
            }).catch(error => {
                if (400 === error.response?.status) {
                    if (0 !== error.response?.data?.errors?.length) {
                        // Get the first error.
                        const e = error.response.data.errors[0]

                        // Display the first error to the user.
                        toast(ToastType.ERROR, t(`enum.cart-error.${e.error}`))

                        error.response.data.errors.forEach(item => {
                            groups.value.forEach(group => {
                                if (group.id === item.group && !group.errors.includes(item.child)) {
                                    group.errors.push(item.child)
                                }
                            })
                        })
                    }
                }
            }).finally(() => {
                loading.value = false
            })
        })
    }

    /**
     * Get the course description.
     *
     * @param {string} course
     */
    function description(course) {
        if (!course) {
            return ''
        }

        const html = trans({ en: course.description_en, fr: course.description_fr })

        return html?.replace(/<p>(\s|&nbsp;)*<\/p>/gi, '')
    }

    /**
     * Show the schedule for the specified group.
     *
     * @param {object} value
     * @return {void}
     */
    function showSchedule(value) {
        group.value = value
        schedule.value?.open()
    }

    /**
     * Format a price value.
     *
     * @param {number|string} value
     * @return {string}
     */
    function price(value) {
        let prefix = '$'
        let suffix = ''

        // if (taxed.value) {
            // suffix = t('labels.tax')
        // }

        return `${prefix}${value} ${suffix}`.trim()
    }

    function progressClasses(group) {
        const filled = group?.filled || 0

        return {
            'bg-success': filled < 100,
            'bg-danger': filled >= 100
        }
    }

    function progressStyle(activity) {
        const filled = getRegistrationPercentage(activity)

        if (-1 === filled) {
            return { display: 'none '}
        }

        return { width: `${filled}%` }
    }

    function imageStyle(activity) {
        if (!activity?.course?.attachment)
            return { 'aspect-ratio': '16/9' }

        return {
            'aspect-ratio': '16/9',
            'background-image': `url(${activity.course.attachment.url})`
        }
    }
</script>
