<template>
    <div v-if="isParent" class="mb-5 mb-lg-10 w-100">
        <div id="kt_app_toolbar" class="app-toolbar  py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack ">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ $t('labels.invoices') }}</h1>
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li class="breadcrumb-item text-muted">
                            <RouterLink :to="{ name: Route.HOME }" class="text-muted text-hover-primary">{{ $t('component.breadcrumbs.home') }}</RouterLink>
                        </li>                    
                        <li class="breadcrumb-item"><span class="bullet bg-gray-400 w-5px h-2px"></span></li>        
                        <li class="breadcrumb-item text-muted">{{ $t('labels.orders') }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="kt_app_content_container" class="app-container container-xxl">
            <ParentHeader/>
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
                <div class="card-header">
                    <div class="card-title">
                        <h2>{{ $t('labels.invoices') }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="flex md:hidden flex-col gap-6">
                        <div v-for="row in rows" :key="row.id" class="flex flex-col gap-4 bg-light rounded p-6">
                            <div class="flex flex-col">
                                <div class="fw-bold">{{ $t('labels.id') }}</div>
                                <div>{{ transform('id', row) }}</div>
                            </div>
                            <div class="flex flex-col">
                                <div class="fw-bold">{{ $t('labels.status') }}</div>
                                <div v-if="row.status == 'pending'"><span class="badge badge-light-danger">{{ t(`enum.order-status.failed`) }}</span></div>
                                <div v-else><span :class="badgeClasses(row)">{{ transform('status', row) }}</span></div>
                            </div>
                            <div class="flex flex-col">
                                <div class="fw-bold">{{ $t('labels.total') }}</div>
                                <div>${{ row.total.toLocaleString() }}</div>
                            </div>
                            <div class="flex flex-col">
                                <div class="fw-bold">{{ $t('labels.date') }}</div>
                                <div>{{ datetime(row.created_at) }}</div>
                            </div>
                            <div class="flex flex-col">
                                <div class="fw-bold">{{ $t('labels.actions') }}</div>
                                <div>
                                    <RouterLink class="btn btn-secondary btn-sm" :to="{ name: Route.ORDER_VIEW, params: { id: row.id } }">{{ $t('labels.details') }}</RouterLink>
                                    <a href="#" class="btn btn-secondary btn-sm ml-1" @click.prevent="download(row.id)">{{ $t('labels.download') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hidden md:block w-full">     
                        <table class="table align-middle table-row-dashed gy-5 dataTable no-footer">
                            <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                                <tr class="text-start text-muted text-uppercase gs-0">
                                    <th class="min-w-100px">{{ $t('labels.id') }}</th>
                                    <th class="min-w-100px">{{ $t('labels.status') }}</th>
                                    <th class="min-w-100px">{{ $t('labels.total') }}</th>
                                    <th class="min-w-100px">{{ $t('labels.date') }}</th>
                                    <th class="min-w-100px text-right">{{ $t('labels.actions') }}</th>
                                </tr>                            
                            </thead>
                            <tbody class="fs-6 fw-semibold text-gray-600">
                                <tr v-if="!rows?.length" class="d-block d-md-table-row">
                                    <td class="d-block d-md-table-cell text-center" colspan="5">{{ $t('labels.empty') }}</td>
                                </tr>
                                <tr v-for="row in rows" :key="row.id">
                                    <td>{{ transform('id', row) }}</td>
                                    <td v-if="row.status == 'pending'"><span class="badge badge-light-danger">{{ t(`enum.order-status.failed`) }}</span></td>
                                    <td v-else><span :class="badgeClasses(row)">{{ transform('status', row) }}</span></td>
                                    <td>${{ row.total.toLocaleString() }}</td>
                                    <td>{{ datetime(row.created_at) }}</td>
                                    <td class="text-right">
                                        <RouterLink class="btn btn-secondary btn-sm" :to="{ name: Route.ORDER_VIEW, params: { id: row.id } }">{{ $t('labels.details') }}</RouterLink>
                                        <a href="#" class="btn btn-secondary btn-sm ml-1" @click.prevent="download(row.id)">{{ $t('labels.download') }}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <BaseContainer v-if="!isParent">

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.orders')"></h1>

            <Breadcrumbs :current="Route.ORDER_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="orders-2024021"
            :editable="false"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            :destroyable="false"
            @view="view">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.order-create') }}</BaseButton>
            </template>

            <template v-if="isAdmin" #parent="{ row, value }">
                <RouterLink v-if="row.user_id" class="text-primary" :to="{ name: Route.PARENT_VIEW, params: { id: row.user_id } }">{{ value }}</RouterLink>
            </template>

            <!-- template #actionsAppend="{ row }">
                <button
                    v-if="isAdmin"
                    class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-danger"
                    @click.prevent="refund(row.id)">
                    <i class="fa-duotone fa-rotate-left"></i>
                </button>
            </template -->

        </DataTable>

    </BaseContainer>

    <ReportModal ref="reportModal" />

</template>

<script setup>
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'
    import { datetime } from '@/composables/dates'
    import { useI18n, useTrans } from '@/composables/i18n'    
    import { useModelIndex } from '@/composables/model-ui'
    import { useOrderStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useOrderStore()
    const router = useRouter()
    const { isAdmin, isParent, isImpersonating } =  useAuth()

    if (isImpersonating.value) {
        store.$reset();
    }

    const reportModal = ref()

    const statuses = computed(() => ([
        { value: 'complete', text: t('enum.order-status.complete') },
        { value: 'credited', text: t('enum.order-status.credited') },
        { value: 'failed', text: t('enum.order-status.failed') },
        { value: 'pending', text: t('enum.order-status.pending') },
        { value: 'processing', text: t('enum.order-status.processing') },
        { value: 'refunded', text: t('enum.order-status.refunded') },
    ]))

    const { rows, from, to, total, page, pages, loading, search, filter, destroy, list } = useModelIndex('order', store, {
        created: t('message.order-created'),
        updated: t('message.order-updated'),
        destroyed: t('message.order-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'status', text: t('labels.status'), options: statuses.value },
        { id: 'total', text: t('labels.total') },
        { id: 'parent', text: t('labels.parent') },
        { id: 'email', text: t('labels.email') },                
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.ORDER_CREATE })
    const edit = (id) => router.push({ name: Route.ORDER_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.ORDER_VIEW, params: { id } })    

    onMounted(() => {
        if (isParent.value) {
            store.index({ perPage: -1 })
        }
    })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'name':   return row.user ? `${row.user.first_name} ${row.user.last_name}` : ''
            case 'email':  return row?.user?.email
            case 'status': return row.status ? t(`enum.order-status.${row.status}`) : ''
            case 'total':  return row.total ? `$${row.total}` : ''
        }

        return row[column]
    }

    function badgeClasses(row) {
        return {
            'badge': true,
            'badge-light-info': row.status === 'credited' || row.status === 'refunded',
            'badge-light-success': row.status === 'complete',
            'badge-light-warning': row.status === 'pending' || row.status === 'processing',
            'badge-light-danger': row.status === 'failed',
        }
    }

    /**
     * Download the invoice.
     * 
     * @return {void}
     */
     function download(id) {
        console.log(id)
        reportModal.value?.generate?.('invoice', {order: id})
    }
</script>
